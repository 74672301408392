import React, {useEffect, useState, Suspense} from 'react';
import { createBrowserHistory } from "history";
import { Router, Switch, Redirect } from "react-router-dom";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import { withFirebase } from './components/Firebase';
const Navigation = React.lazy(() => import ("./components/Navigation/Navigation"));


const hist = createBrowserHistory();

const App = ({firebase}) => {
    const [user, setUser] = useState(null);
    const [userRole, setUserRole] = useState(false);

    useEffect(() => {
        firebase.auth.onAuthStateChanged(authUser => {
            if(authUser) {
                const rgx = /\./g;
                const email = authUser.email;
                const userEmail = email.replace(rgx, '_');

                firebase.getUserData(userEmail).once('value')
                    .then(snapshot => {
                        let currentRole = 'default';
                        if(snapshot.val()) {
                            currentRole = snapshot.val().userRole;
                        }
                        setUser(authUser);
                        setUserRole(currentRole);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else {
                setUser(false);
                setUserRole(false);
            }
        });
    }, [firebase]);

    const Loading = () => <div>Loading...</div>;

    return (
        <Suspense fallback={<Loading/>}>
            <Router history={hist}>
                <Navigation authUser={{user}} role={userRole} />
                <Switch>
                    <Redirect from="/" to="/admin/table" />
                </Switch>
            </Router>
        </Suspense>
        )
};

export default withFirebase(App);
