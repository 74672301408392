import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB6D0qjDcOjpCvhl9cq70Z6Ysl1-E65hSA",
  authDomain: "kevin-orthopedic.firebaseapp.com",
  databaseURL: "https://kevin-orthopedic.firebaseio.com",
  projectId: "kevin-orthopedic",
  storageBucket: "kevin-orthopedic.appspot.com",
  messagingSenderId: "345684524233",
  appId: "1:345684524233:web:83e37101ed4cc059"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.database();
    this.strg = app.storage();
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  addOpenedBy = async (id, user) => {
    const data = await this.db.ref(`adminpanel-orders-view/${id}`).update(
      {
        openedBy: user
      },
      error => {
        if (error) {
          console.log(error);
          return false;
        } else {
          return true;
        }
      }
    );
    console.log(data);
  };

  addOrderStatus = async (id, orderStatusState) => {
    const data = await this.db.ref(`adminpanel-orders-view/${id}`).update(
      {
        isSubmitted: orderStatusState
      },
      error => {
        if (error) {
          console.log(error);
          return false;
        } else {
          return true;
        }
      }
    );
    console.log(data);
  };

  addOrderCompleteDate = async (id, date) => {
    const response = await this.db.ref(`adminpanel-orders-view/${id}`).update({
      completeDate: date
    },error => {
      if (error) {
        console.log(error);
        return false;
      } else {
        return true;
      }
    })
  }

  order = uid => this.db.ref(`adminpanel-orders-view/${uid}`);
  orders = () => this.db.ref("adminpanel-orders-view");
  updateImgCount = async (orderId, count) => {
    await this.db.ref(`adminpanel-orders-view/${orderId}`).update({
      imgCount: count
    })
  }
  storage = () => this.strg.ref();

  getUserData = email => this.db.ref(`/users/${email}`);
  getUsers = () => this.db.ref("/users/");

  getFileList = async (path) => {
    const storageRef = this.strg.ref();
    let listRef = storageRef.child(path);

    let count = 0;
    await listRef.listAll()
      .then(res => {
        count = res.items.length;
      })
      .catch(error => {
        console.log(error);
      })

    return count;
  }
}
export default Firebase;
